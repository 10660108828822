'use client'

import type { AnchorHTMLAttributes, ReactNode } from 'react'

// eslint-disable-next-line custom-rules/no-next-link
import NextLink, { LinkProps } from 'next/link'

import useLocale from '@hooks/client/useLocale'

export default function Link(
  props: Omit<LinkProps, 'locale' | 'legacyBehavior'> & {
    children?: ReactNode
    href: string
  } & AnchorHTMLAttributes<Element>,
): JSX.Element {
  // Force default locale link for SEO purpose
  // https://stackoverflow.com/questions/66615874/how-to-force-default-locale-in-the-url-when-clicking-a-link-in-next-js
  const locale = useLocale()
  const link = `/${locale}/${props.href}`
  // eslint-disable-next-line prefer-regex-literals
  return <NextLink {...props} href={link.replace(new RegExp('//', 'g'), '/')} locale={false} scroll />
}
